import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import * as yup from "yup";
import { useLocation } from "@gatsbyjs/reach-router";
import Seo from "~/components/Seo";
import TextFormItem from "~/components/forms/TextFormItem";
import { SchemaFieldValues } from "~/utils/SchemaFieldValues";
import TextareaFormItem from "~/components/forms/TextareaFormItem";
import { SubmitButton } from "~/components/forms/index";
import useFormikStatusSubmit from "~/hooks/useFormikStatusSubmit";
import { useApiMutation } from "~/hooks/apiContext";
import ErrorMessages from "~/components/ErrorMessages";
import { Link } from "gatsby";

const validationSchema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    emailAddress: yup.string().email().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
  })
  .required();

type FormValues = SchemaFieldValues<typeof validationSchema>;

const initialValues: FormValues = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  subject: "",
  message: "",
};

function ContactPage() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = params.get("source") ?? undefined;
  const [submittedSuccessfully, setSubmittedSucessfully] = useState(false);

  const { error, mutateAsync, reset } = useApiMutation(
    (c, values: FormValues) =>
      c.createContact({
        ...values,
        source,
      }),
  );
  const onSubmit = useFormikStatusSubmit(
    async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      await mutateAsync(values);
      helpers.resetForm();
      reset();
      setSubmittedSucessfully(true);
    },
  );

  return (
    <div className="Site-inner">
      <div className="Content-outer">
        <main className="Main Main--page">
          <div className="Index-page-content container">
            <h2>Contact Us</h2>
            {submittedSuccessfully ? (
              <div>
                Thank you! We will get in touch with you soon. In the meantime,
                if you&apos;d like to learn more about ProblemScape: Value of
                Xperts, and try the first chapter of the game, please visit our{" "}
                <Link to="/support-center/">support center</Link>.
              </div>
            ) : (
              <>
                <p>
                  For inquiries about our games, or any thoughts/feedback,
                  please email{" "}
                  <a
                    href="mailto:info@roundedlearning.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@roundedlearning.com
                  </a>{" "}
                  or use the form below:
                </p>
                <ErrorMessages errors={[error]} />
                <Formik
                  onSubmit={onSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  <Form>
                    <TextFormItem
                      name="firstName"
                      label="First name"
                      autoFocus
                    />
                    <TextFormItem name="lastName" label="Last name" />
                    <TextFormItem name="emailAddress" label="Email address" />
                    <TextFormItem name="subject" label="Subject" />
                    <TextareaFormItem name="message" label="Message" rows={6} />
                    <SubmitButton label="Submit" />
                  </Form>
                </Formik>
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export function Head() {
  return <Seo title="Contact Us" />;
}

export default ContactPage;
