import React, { FocusEvent } from "react";
import { useField, useFormikContext } from "formik";
import FormItem, { FormItemProps } from "./FormItem";

type TextareaFormItemProps = Pick<FormItemProps, "label" | "name" | "style"> & {
  readonly autoFocus?: boolean;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly autoComplete?: HTMLInputElement["autocomplete"];
  readonly rows?: number;
  readonly cols?: number;
};

function TextareaFormItem({
  name,
  placeholder,
  autoComplete,
  autoFocus,
  rows,
  cols,
  style,
  disabled,
  label,
}: TextareaFormItemProps) {
  const [{ onBlur: formikOnBlur, ...field }] = useField<string>(name);
  const { isSubmitting } = useFormikContext();
  const fieldId = `${name}Field`;

  const onBlur = (e: FocusEvent<any>) => {
    // See https://github.com/formium/formik/issues/2062
    // i.e. this is to support autofocus
    const relatedTarget = e.relatedTarget;
    if (
      relatedTarget &&
      "tagName" in relatedTarget &&
      ((relatedTarget as Element).tagName === "A" ||
        (relatedTarget as Element).tagName === "BUTTON" ||
        (relatedTarget as Element).tagName === "INPUT")
    ) {
      return;
    }

    formikOnBlur(e);
  };

  return (
    <FormItem name={name} label={label} fieldId={fieldId} style={style}>
      <textarea
        id={fieldId}
        disabled={disabled || isSubmitting}
        className="input"
        placeholder={placeholder}
        onBlur={onBlur}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        rows={rows}
        cols={cols}
        {...field}
      />
    </FormItem>
  );
}

export default TextareaFormItem;
